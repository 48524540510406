import React, { useState } from 'react';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Invitation from './Invitation';
import Card from '../../components/Card';

export default function Invitations(props) {
  const { event, title, responses = [], header, footer, onViewMessages } = props;

  const [timestamp] = useState(new Date().toISOString());

  const invitations = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          response: {
            $in: responses
          },
          createdAt: {
            $lt: timestamp
          }
        }
      }
    },
    pagination: {
      pageSize: 100
    }
  });

  if (invitations.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Card className="flex flex-col p-4 space-y-4">
      <div className="flex space-x-2 items-center">
        <div className="text-xl font-semibold text-cs-gray-900">
          {title}
        </div>
        <div className="bg-cs-blue-500 bg-opacity-20 text-cs-blue-500 font-semibold rounded-lg px-3">
          {invitations.data.length}
        </div>
      </div>
      <div className="flex-1 bg-cs-gray-100 rounded-lg h-100" style={{ minHeight: '74px' }}>
        {header ? header(invitations.data.length) : null}
        {_.sortBy(invitations.data, function(invitation) {
          return {
            Yes: 3,
            Maybe: 2,
            Pending: 1,
            No: 0
          }[invitation.data.response];
        }).map(function(invitation, index) {
          return (
            <Invitation
              key={invitation.id || invitation.cid}
              invitation={invitation}
              onViewMessages={onViewMessages}
            />
          );
        })}
        {footer}
      </div>
    </Card>
  );
};
