import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { DateTime } from 'luxon';

PostmarkMessageDeliveryStatus.propTypes = {
  message: PropTypes.object.isRequired
};

export default function PostmarkMessageDeliveryStatus(props) {
  const { message } = props;

  const postmarkEvent = useConnect('postmarkEvent.byId', {
    id: message.data.postmarkMessageId
  }, {
    enabled: !!message.data.postmarkMessageId
  });

  if (!message.data.postmarkMessageId) {
    return 'No delivery information';
  }

  if (postmarkEvent.state === PayloadStates.FETCHING) {
    return '...';
  }

  if (postmarkEvent.state === PayloadStates.NOT_FOUND) {
    return 'Sent';
  }

  // return (
  //   <div>
  //     {postmarkEvent.data.event} @ {moment.unix(postmarkEvent.data.timestamp).format('h:mm A, MMM D YYYY')}
  //   </div>
  // );

  const events = postmarkEvent.data.MessageEvents.filter(function(event) {
    // filter our events we don't carea bout
    return event.Type !== 'SubscriptionChanged';
  }).sort(function(event) {
    return -DateTime.fromISO(event.ReceivedAt).toMillis();
  });

  const event = events[0];

  const eventTypeMap = {
    Transient: 'No delivery information',
    Opened: 'Opened',
    LinkClicked: 'Opened',
    Delivered: 'Unread',
    Bounced: 'Undeliverable'
  };

  if (!event) {
    return eventTypeMap.Transient;
  }

  return `${eventTypeMap[event.Type] || event.Type}`;
};
