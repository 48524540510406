import { useEffect } from 'react';
import * as FullStory from '@fullstory/browser';
import { useUser } from '@lore/auth';
import { useConfig } from '@lore/config';

export default function useFullStory(props) {
  const currentUser = useUser();
  const config = useConfig();

  useEffect(() => {
    const { enabled } = config.fullstory;

    if (enabled) {
      FullStory.setUserVars({
        uid: currentUser.id,
        displayName: `${currentUser.data.firstName} ${currentUser.data.lastName}`,
        email: currentUser.data.email,
      });
      FullStory.restart();
    }
  }, []);

  return null;
};
