import React, { useState } from 'react';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import Project from '../projects-past/Project';
import Loader from '../../components/Loader';
import EmptyState, { EmptyStateActions } from '../../components/EmptyState';
import { useConnect, UseQueryConnect } from '@lore/query-connect';
import SectionHeader from '../../components_profiles/SectionHeader';
import { LinkButton, OutlineButton } from '../../components/Button';
import { LightBulbIcon } from '@heroicons/react/24/outline';

export default function PastProjects(props) {
  const currentUser = useUser();

  const projectBookmarks = useConnect('bookmark.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          projectId: {
            $not: null
          },
          project: {
            $where: {
              completed: true
            }
          }
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  if (projectBookmarks.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <div className="space-y-4">
      <SectionHeader
        title="Idea Hub"
        subtitle={`${projectBookmarks.data.length} idea${projectBookmarks.data.length === 1 ? '' : 's'} bookmarked`}
      />
      {projectBookmarks.data.length === 0 ? (
        <EmptyState
          icon={LightBulbIcon}
          title="Looks like you don't have any ideas bookmarked yet."
          subtitle="After bookmarking projects in the Idea Hub, you will see them here."
        >
          <EmptyStateActions>
            <LinkButton
              button={OutlineButton}
              to="/idea-hub"
              label="Explore Idea Hub"
            />
          </EmptyStateActions>
        </EmptyState>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
          {projectBookmarks.data.map(function(projectBookmark, index) {
            return (
              <UseQueryConnect key={index} _key="project.byId" params={{ id: projectBookmark.data.projectId }}>
                {function(project) {
                  if (project.state === PayloadStates.FETCHING) {
                    return (
                      <Loader key={index}/>
                    );
                  }

                  return (
                    <Project
                      key={index}
                      project={project}
                    />
                  );
                }}
              </UseQueryConnect>
            );
          })}
        </div>
      )}
    </div>
  );
};
