import * as FullStory from '@fullstory/browser';

export default function(config) {
  const { fullstory } = config;

  if (
    fullstory.enabled &&
    fullstory.orgId
  ) {
    FullStory.init({
      orgId: fullstory.orgId,
      // Manually delay data capture so that FullStory only starts recording
      // when we want it to (after the user has logged is and is identified)
      startCaptureManually: true
    });
  }
};
