import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
// import ProjectBookmark from '../../../components/ProjectBookmark';
import { useUser } from '@lore/auth';
import Date from '../../projects/Project/Date';
import HeaderIcon from './HeaderIcon';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import Images from '../../projects-past/Project/Images';
import Documents from '../../projects-past/Project/Documents';
import Testimonials from '../../projects-past/Project/Testimonials';
import School from '../../projects/Project/School';
import getUploadInfo from '../../evaluation-project-educator/Uploads/getUploadInfo';
import getUrlForUpload from '../../user/User/getUrlForUpload';
import { getActivityIconMap } from '../../../maps/activityIconMap';
import ProjectBookmark from '../../../components/ProjectBookmark';

Header.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Header(props) {
  const { project } = props;

  const currentUser = useUser();

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  const upload = useConnect('upload.first', {
    where: {
      eager: {
        $where: {
          evaluationProjectEducator: {
            $where: {
              projectId: project.id
            }
          },
          $or: [
            'png',
            'jpeg',
            'jpg',
            'heic',
            'gif'
          ].map(function(value) {
            return {
              name: {
                $ilike: `%.${value}`
              }
            };
          })
        }
      }
    }
  });

  const info = getUploadInfo(upload.data.name);
  const url = getUrlForUpload(upload);
  const iconMap = getActivityIconMap(activity);

  return (
    <div className="relative">
      {/*<div className="h-64x aspect-[16/9] rounded-cs-10 overflow-hidden w-full relative bg-cs-gray-500  mb-4">*/}
      <div className="h-64 sm:h-96 md:h-[496px] xl:h-96 rounded-cs-10 overflow-hidden w-full relative bg-cs-gray-300  mb-4">
        <div className="absolute z-10 top-2 right-4">
          <ProjectBookmark
            project={project}
            user={currentUser}
          />
        </div>
        {info.isImage ? (
          <img
            className="absolute left-0 top-0 object-cover object-center h-full w-full"
            src={url}
            alt="image"
          />
        ) : (
          <div className="flex flex-col items-center justify-center h-full space-y-1">
            {/*<info.icon.hack className="h-20 w-20 text-white"/>*/}
            {/*<div className="text-white">*/}
            {/*  {info.extension ? `.${info.extension}` : '[filetype unknown]'}*/}
            {/*</div>*/}
            <iconMap.icon className="h-16 w-16 stroke-1 text-gray-100"/>
            <div className="text-base text-gray-100">
              {activity.data.name}
            </div>
          </div>
        )}
      </div>
      <div className="text-3xl text-cs-gray-900 font-semibold mb-2">
        {project.data.title}
      </div>
      <div className="flex flex-wrap gap-x-4 gap-y-2">
        <Date project={project} />
        <School project={project} />
        <Images project={project} />
        <Documents project={project} />
        <Testimonials project={project} />
      </div>
    </div>
  );
};
