import { extractTextFromQuery } from '../../../utils/query-utils';

export default {
  param: extractTextFromQuery,
  query: function (text) {
    // const words = text.trim().split(' ');
    const words = text.trim();

    if (words) {
      return {
        school: {
          $where: {
            agencyName: {
              $ilike: `%${words}%`
            }
          }
        }
      };
    }
  }
}
