import React from 'react';
import PropTypes from 'prop-types';
import { useConfig } from '@lore/config';
import ScrollToTop from '../components/ScrollToTop';
import useRouter from '../hooks/useRouter';
import useIntercom from '../hooks/useIntercom';
import Impersonation from './Impersonation';
import ErrorBoundary from './ErrorBoundary';
import { Outlet } from 'react-router-dom';

export default function Layout(props) {
  const { location, history } = useRouter();
  const config = useConfig();

  useIntercom();

  return (
    <>
      <ScrollToTop />
      <Impersonation />
      <ErrorBoundary key={location.pathname} config={config}>
        <Outlet />
      </ErrorBoundary>
    </>
  );
};
