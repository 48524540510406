import React from 'react';
import { Helmet } from 'react-helmet-async';
import Projects from './Projects';
import useRouter from '../../hooks/useRouter';
import PageHeader from '../people-people/PageHeader';

export default function Layout(props) {
  const router = useRouter();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Idea Hub</title>
      </Helmet>
      <PageHeader
        title="Discover the Idea Hub!"
        subtitle={(
          <>
            Check out projects completed by educators and community partners across our
            communities. Gain insight into their ideas, real-world learning journeys, and
            the impact they've had on learners — shared in the voices of educators. You
            might just find the spark for your next great idea!
          </>
        )}
      />
      <Projects
        router={router}
      />
    </div>
  );
}
