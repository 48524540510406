import React from 'react';
import PropTypes from 'prop-types';
import ClampableText from '../../../components/ClampableText';
import Subjects from './Subjects';
import Ages from './Ages';
import Activities from './Activities';
import SectionHeader from '../../../components_profiles/SectionHeader';
import Card from '../../../components/Card';
import Students from './Students';
import Skills from './Skills';
import Experience from './Experience';
import Participation from '../Project/Participation';
import Creator from './Creator';
import { PayloadStates } from '@lore/utils';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import StewardButton from './StewardButton';

Idea.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Idea(props) {
  const { project } = props;

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  function getTitle() {
    if (creator.state === PayloadStates.FETCHING) {
      return 'The original idea';
    }

    if (creator.data.inactive && creator.data.firstName === 'Previous') {
      return `Previous educator's original idea`;
    }

    return `${creator.data.firstName}'s original idea`;
  }

  return (
    <div>
      <div className="space-y-2">
        <SectionHeader
          title={getTitle()}
        />
        <div className="text-base text-cs-gray-500">
          <ClampableText markup={true}>
            {project.data.interactionDescription || '[No summary provided]'}
          </ClampableText>
        </div>
        {project.data.desiredOutcome && (
          <div className="text-base text-cs-gray-500">
            <ClampableText markup={true}>
              {project.data.desiredOutcome}
            </ClampableText>
          </div>
        )}
      </div>
      {/*<div className="block xl:hidden px-6 pb-6 space-y-6">*/}
      {/*  /!*<Date project={project} />*!/*/}
      {/*  <Ages project={project} />*/}
      {/*  <Activities project={project} />*/}
      {/*  <Subjects project={project} />*/}
      {/*</div>*/}
      <div className="block xl:hidden mt-4 space-y-4">
        <Creator project={project} />
        <Card>
          <div className="px-6 py-6 space-y-6">
            <Ages project={project} />
            <Students project={project}/>
            <Subjects project={project} />
            <Skills project={project} />
            <Experience project={project} />
          </div>
        </Card>
        <StewardButton project={project} />
        {/*<Creator project={project} />*/}
        {/*<Participation project={project}/>*/}
      </div>
    </div>
  );
};
