import React from 'react';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { SolidButton } from '../../../components/Button';
import GrayOutlineButton from '../../../components/Button/OutlineButton.gray';
import ComposableProjectHeader from '../../../components_cards/ComposableProjectHeader';
import DividerCard from '../../../components/DividerCard';
import TagRow from '../../../components_cards/tags/TagRow';
import ProjectTag from '../../../components_cards/tags/ProjectTag';
import ExpiredTag from '../../../components_cards/tags/_resource/ExpiredTag';
import CreatorTag from '../../../components_cards/tags/CreatorTag';
import ProjectMissingFieldsTag from '../../../components_cards/ComposableProjectHeader/ProjectMissingFieldsTag';
import Questions from '../../my-past/ProjectCreated/Questions';

export default function ActiveProject(props) {
  const { project } = props;

  const launch = useDialogLauncher();

  function onClickView() {
    launch(DialogManager.ProjectManage.Config(project.id, `/view`));
  }

  function onClickEdit() {
    launch(DialogManager.ProjectManage.Config(project.id, `/edit`));
  }

  function onClickInvite() {
    launch(DialogManager.ProjectManage.Config(project.id, `/invite-populate`));
  }

  function onClickManage() {
    launch(DialogManager.ProjectManage.Config(project.id, `/manage`));
  }

  function onClickClose() {
    launch(DialogManager.ProjectClose.Config(project.id));
  }

  return (
    <DividerCard>
      <ComposableProjectHeader
        project={project}
        tags={(
          <>
            <TagRow>
              <ProjectTag />
              {/*<CreatorTag />*/}
              {/*<ProjectMissingFieldsTag project={project} />*/}
              {/*<ExpiredTag resource={project} />*/}
            </TagRow>
            <Questions project={project} />
          </>
        )}
        showTags={false}
        showCreator={false}
      />
      <div className="w-full bg-cs-gray-50 py-4 px-4 flex justify-between items-center flex-wrap gap-2">
        <div className="flex flex-wrap gap-2">
          {/*<GrayOutlineButton*/}
          {/*  onClick={onClickView}*/}
          {/*  label="View project"*/}
          {/*/>*/}
          {/*<GrayOutlineButton*/}
          {/*  onClick={onClickEdit}*/}
          {/*  label="Edit project"*/}
          {/*/>*/}
        </div>
        <div className="flex flex-wrap gap-2">
          <GrayOutlineButton
            onClick={onClickClose}
            label="Close project"
          />
          {/*<GrayOutlineButton*/}
          {/*  onClick={onClickInvite}*/}
          {/*  label="Invite partners"*/}
          {/*/>*/}
          <SolidButton
            onClick={onClickManage}
            label="Coordinate project"
          />
        </div>
      </div>
    </DividerCard>
  );
}
