import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../../components/Loader';
import TagTypes from '../../../../constants/TagTypes';
import TagCheckboxCards from '../../_common/TagCheckboxCards';

Entries.propTypes = {
  expertiseType: PropTypes.object.isRequired,
  userExpertises: PropTypes.object.isRequired
};

export default function Entries(props) {
  const { expertiseType, userExpertises, onSelect, onDestroy, onUpdate } = props;

  const expertises = useConnect('tag.find', {
    where: {
      eager: {
        $where: {
          type: TagTypes.Expertise,
          childTagTrees: {
            $where: {
              parentId: expertiseType.id
              // parent: {
              //   $where: {
              //     id: expertiseType.id
              //   }
              // }
            }
          }
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'name asc'
    }
  });

  if (expertises.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  // console.log(userExpertises.data.length);

  return (
    <TagCheckboxCards
      tags={expertises}
      facadeTagNodes={userExpertises}
      onChange={function(tag, facadeTagNode) {
        if (facadeTagNode?._tagNode.data.description) {
          return onDestroy(tag, facadeTagNode);
        }

        return onSelect(tag);
      }}
      onUpdateDescription={onUpdate}
      // selectable={selectable}
    />
  );
}
