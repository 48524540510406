import React, { useState } from 'react';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Claim from './Claim';

export default function Claims(props) {
  const { offer, responses = [], emptyState, onViewMessages } = props;

  const [timestamp] = useState(new Date().toISOString());

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          response: {
            $in: responses
          },
          createdAt: {
            $lt: timestamp
          }
        }
      }
    },
    pagination: {
      pageSize: 100
    }
  });

  if (claims.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (claims.data.length === 0) {
    return (
      <div className="space-y-2">
        {emptyState}
      </div>
    );
  }

  return _.sortBy(claims.data, function(claim) {
    return {
      Connect: 0,
      Pending: 1,
      Decline: 2
    }[claim.data.response];
  }).map(function(claim, index) {
    return (
      <Claim
        key={claim.id || claim.cid}
        claim={claim}
        onViewMessages={onViewMessages}
      />
    );
  });
};
