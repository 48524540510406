import { extractTextFromQuery } from '../../../utils/query-utils';

export default {
  param: extractTextFromQuery,
  query: function(text) {
    const words = text.trim().split(' ');

    if (text) {
      return {
        $or: [
          {
            $or: words.map(function (word) {
              return {
                project: {
                  $where: {
                    title: {
                      $ilike: `%${word}%`
                    }
                  }
                }
              };
            }).concat(words.map(function (word) {
              return {
                project: {
                  $where: {
                    activity: {
                      $where: {
                        name: {
                          $ilike: `%${word}%`
                        }
                      }
                    }
                  }
                }
              };
            }))
          },
          {
            $or: words.map(function (word) {
              return {
                event: {
                  $where: {
                    title: {
                      $ilike: `%${word}%`
                    }
                  }
                }
              };
            }).concat(words.map(function (word) {
              return {
                event: {
                  $where: {
                    activity: {
                      $where: {
                        name: {
                          $ilike: `%${word}%`
                        }
                      }
                    }
                  }
                }
              };
            }))
          }
        ]
      }
    }
  }
}
