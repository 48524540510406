import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Bookmark from './Bookmark';
import { useQueryMutation } from '../../hooks/@lore/query-connect';
import classNames from 'classnames';

ProjectBookmark.propTypes = {
  user: PropTypes.object.isRequired,
  project: PropTypes.object
};

export default function ProjectBookmark(props) {
  const { user, project } = props;

  const projectBookmark = useConnect('bookmark.first', {
    where: {
      eager: {
        $where: {
          creatorId: user.id,
          projectId: project.id
        }
      }
    }
  });

  const mutation_create = useQueryMutation('bookmark.create');
  const mutation_destroy = useQueryMutation('bookmark.destroy', projectBookmark);

  const isLoading = (
    mutation_create.isLoading ||
    mutation_destroy.isLoading
  );

  function onBookmark() {
    mutation_create.mutate({
      creatorId: user.id,
      projectId: project.id
    }, {
      onSuccess: function(data) {
        projectBookmark._removeQuery();
      }
    });
  }

  function onRemoveBookmark() {
    mutation_destroy.mutate(null, {
      onSuccess: function(data) {
        projectBookmark._removeQuery();
      }
    });
  }

  if (projectBookmark.state === PayloadStates.FETCHING) {
    return (
      <Bookmark
        className="opacity-70"
        tooltip="Fetching status..."
      />
    );
  }

  if (
    projectBookmark.state === PayloadStates.NOT_FOUND ||
    projectBookmark.state === PayloadStates.DELETED
  ) {
    return (
      <Bookmark
        className={classNames(
          isLoading && 'opacity-70'
        )}
        onClick={isLoading ? () => {} : onBookmark}
        tooltip="Bookmark project"
      />
    );
  }

  return (
    <Bookmark
      className={classNames(
        isLoading && 'opacity-70'
      )}
      selected={true}
      onClick={isLoading ? () => {} : onRemoveBookmark}
      tooltip="Remove bookmark"
    />
  );
}
