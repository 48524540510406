import React from 'react';
import Header from './Header';
import Date from '../../projects/Project/Date';
import Creator from '../../projects/Project/Creator';
import Participation from '../../projects/Project/Participation';
import classNames from 'classnames';
import School from '../../projects/Project/School';
import Distance from '../../projects/Project/Distance';
import Location from '../../projects/Project/Location';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import Questions from '../../my-past/ProjectCreated/Questions';
import Testimonials from './Testimonials';
import Images from './Images';
import Collaborators from './Collaborators';
import DateIcon from '../../story/Story/DateIcon';
import HeaderIcon from '../../story/Story/HeaderIcon';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import Image from './Image';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import Documents from './Documents';
import ProjectBookmark from '../../../components/ProjectBookmark';
import { useUser } from '@lore/auth';

export default function Project(props) {
  const { project, className, children } = props;

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  const currentUser = useUser();
  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.ProjectPast.Config(project.id));
  }

  return (
    <div className={classNames(
      'flex flex-col bg-white',
      'shadow-cs-flat rounded-cs-10',
      '-m-0.5 border-2 border-cs-gray-100',
      'relative overflow-hidden',
      'hover:bg-cs-orange-50 cursor-pointer',
      className
    )}>
      <div className="absolute z-10 top-6 right-6">
        <ProjectBookmark
          project={project}
          user={currentUser}
        />
      </div>
      {/*<Link to={`/projects/${project.id}`} className="absolute w-full h-full" />*/}
      <div className="flex flex-col py-4 px-4 space-y-2" onClick={onClick}>
        {/*<Header project={project}/>*/}
        <Image project={project} />
        <div className="text-lg text-cs-gray-900 font-semibold line-clamp-5x">
          {project.data.title || activity.data.name}
        </div>
        <div className="flex flex-col sm:flex-row items-start justify-between">
          <div className="flex flex-1 flex-wrap gap-x-4 gap-y-2 mb-2 sm:mb-0">
            {/*<Date project={project}/>*/}
            {/*<Time project={project} />*/}
            {/*<Creator project={project} />*/}
            <School project={project} />
            {/*<Images project={project} />*/}
            {/*<Documents project={project} />*/}
            {/*<Testimonials project={project} />*/}
            {/*<Distance project={project} />*/}
            <Location project={project} />
          </div>
          {/*<Distance project={project} />*/}
        </div>
      </div>
      {/*<div className={classNames(*/}
      {/*  'w-full py-4 px-4',*/}
      {/*  'bg-cs-gray-50',*/}
      {/*  '-mt-px border-t border-cs-gray-100',*/}
      {/*)}>*/}
      {/*  <Collaborators project={project} />*/}
      {/*</div>*/}
    </div>
  );
}
