import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import titleCase from '../../utils/titleCase';
import Text from '../../typography';
import Tooltip from '../../pages/_post-opportunity/forms/Tooltip';

SiteSearchField.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default function SiteSearchField(props) {
  const {
    label,
    error,
    description,
    disabled,
    placeholder = "Enter school name...",
    onChange,
    optional = false,
    required = false,
    tooltip,
    icon,
    selected
  } = props;

  const [query, setQuery] = useState(selected.data.name);
  const currentUser = useUser();

  const SEARCH_RADIUS = 100;

  const sites = useConnect('site.find', {
    where: {
      eager: {
        $where: query ? {
          // $or: [
          //   {
          //     name: {
          //       $ilike: `%${query}%`
          //     }
          //   }
          // ],
          name: {
            $ilike: `%${query}%`
          },
          _distance: {
            radius: SEARCH_RADIUS,
            latitude: currentUser.data.latitude,
            longitude: currentUser.data.longitude,
            // address: state.value.address
          }
        } : {
          _distance: {
            radius: SEARCH_RADIUS,
            latitude: currentUser.data.latitude,
            longitude: currentUser.data.longitude,
            // address: state.value.address
          }
        }
      }
    },
    pagination: {
      pageSize: 20,
      order: 'name asc'
    }
  });

  const _selected = selected.data.siteId ?
    _.find(sites.data, m => m.id === selected.data.siteId) || '':
    '';

  function onSelected(site) {
    onChange({
      siteId: site.id,
      name: site.data.name || '',
      addressLine1: site.data.addressLine1 || '',
      addressLine2: site.data.addressLine2 || '',
      addressLine3: site.data.addressLine3 || '',
      city: site.data.city || '',
      state: site.data.state || '',
      zipCode: site.data.zipCode || ''
    });
  }

  return (
    <div>
      {label && (
        <div className="relative text-sm text-cs-gray-500 mb-1">
          {required && (
            <span className="inline-block text-cs-orange-500">*</span>
          )}
          <span className="flex items-center space-x-1">
            <Text.Base>
              {label}
            </Text.Base>
            {tooltip && (
              <div>
                <Tooltip tooltip={tooltip} label={true} />
              </div>
            )}
          </span>
          {optional && (
            <div className="absolute top-0 right-0 text-cs-gray-300 text-sm leading-6">
              Optional
            </div>
          )}
        </div>
      )}
      <Combobox value={_selected} onChange={onSelected}>
        <div className="relative mt-1">
          <div className="">
            <Combobox.Input
              className={classNames(
                // 'form-control',
                'relative w-full bg-white',
                'py-2 pl-3 pr-10 text-left',
                'text-cs-gray-900',
                '-m-px border border-cs-gray-200 rounded-md',
                'placeholder:text-cs-gray-400 placeholder:font-normal',
                'focus:ring-cs-blue-500 focus:border-cs-blue-500',
                error && 'is-invalid'
              )}
              placeholder={placeholder}
              displayValue={(site) => site?.data?.name}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-cs-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery(selected ? selected.data.name : '')}
          >
            <Combobox.Options className={classNames(
              'absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base',
              // 'shadow-lg',
              'shadow-cs-flat',
              '-mx-px border border-cs-gray-200 border-opacity-50',
              'ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10',
            )}>
              {sites.state === PayloadStates.FETCHING ? (
                <div className="relative cursor-default select-none py-2 px-4 text-cs-gray-900">
                  Searching...
                </div>
              ) : (sites.data.length === 0 && query !== '') ? (
                <div className="relative cursor-default select-none py-2 px-4 text-cs-gray-900">
                  Nothing found.
                </div>
              ) : (
                sites.data.map((site) => (
                  <Combobox.Option
                    key={site.id}
                    className={({ active }) => classNames(
                      'relative cursor-default select-none py-2 pl-10 pr-4',
                      active ? 'bg-cs-blue-500 text-white' : 'text-cs-gray-900'
                    )}
                    value={site}
                  >
                    {({ selected, active }) => (
                      <>
                        <div>
                          <div
                            className={classNames(
                              'text-base truncatex'
                            )}
                          >
                            {site.data.name}
                          </div>
                          <div className={classNames(
                            'text-sm mt-0.5x',
                            active ? 'text-white' : 'text-cs-gray-500'
                          )}>
                            {titleCase(site.data.addressLine1)}, {titleCase(site.data.city)}, {site.data.state}, {site.data.zipCode}
                          </div>
                        </div>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {error ? (
        <div className="mt-2 text-sm text-red-500">
          {error}
        </div>
      ) : null}
      {description ? (
        <Text.SmGrayMedium className="mt-2">
          Searching within {SEARCH_RADIUS} miles of {currentUser.data.zipCode}. {description}
        </Text.SmGrayMedium>
      ) : null}
    </div>
  );
};
