import { extractTextFromQuery } from '../../../utils/query-utils';

export default {
  param: extractTextFromQuery,
  query: function(text) {
    if (text) {
      return {
        $or: [
          {
            biography: {
              $ilike: `%${text}%`
            }
          },
          {
            tagNodes: {
              $where: {
                description: {
                  $ilike: `%${text}%`
                }
              }
            }
          },
          {
            tags: {
              $where: {
                name: {
                  $ilike: `%${text}%`
                }
              }
            }
          }
        ]
      }
    }
  }
}
